import React, { lazy, Suspense, useState } from "react"
import { StaticQuery, graphql, navigate } from "gatsby"
import { useLocation } from '@reach/router'

import {
  dataStore,
  filterStore,
  locationStore,
  mapStore,
  pageStore,
} from "state/store-zustand";

import Logo from 'components/header/logo'
import ArrowLeft from "images/svg/icons/arrow-left.svg"
import DisplayField from 'components/search/DisplayField'

const CitySelect = lazy(() => import('components/filters/CitySelect'), { ssr: false })
const SmartBanner = lazy(() => import('react-smartbanner'), { ssr: false })

import "./mainMenu.scss"

// Helper function to render links
const renderLink = linkItem => {

  if (process.env.GATSBY_ENV === 'staging' && linkItem.url === 'https://app.vibemap.com/discover') {
    linkItem.url = 'http://localhost:3000/discover'
  }

  const isFullLink = linkItem.url.includes('http')

  if (linkItem.target || isFullLink) {
    return (
      <a
        rel="noreferrer noopener"
        href={linkItem.url}
        title={linkItem.label}
      >
        {linkItem.label}
      </a>
    )
  } else {
    const link = linkItem.url.replace(`https://vibemap.com`, ``)

    return (
      <a href={link} title={linkItem.label}>
        {linkItem.label}
      </a>
    )
  }
}

const MainMenu = (props) => {
  const { city, citySelected, preLoadLinks, showBanner } = props

  const citiesOfficial = dataStore((state) => state.citiesAll)
  const citiesCurrent = filterStore((state) => state.citiesCurrent)
  const setPlacesLoaded = dataStore((state) => state.setPlacesLoaded)

  const isClient = pageStore((state) => state.isClient)
  const isPhone = pageStore((state) => state.isPhone)

  const filters = filterStore((state) => state.filters)
  const setFilters = filterStore((state) => state.setFilters)

  const cityCurrent = city || locationStore((state) => state.cityCurrent)
  const setCityCurrent = locationStore((state) => state.setCityCurrent)
  const setLocationCurrent = locationStore((state) => state.setLocationCurrent)

  const setMapUpdated = mapStore((state) => state.setMapUpdated)

  // Set default from template context
  const [selectedCity, setSelectedCity] = useState(cityCurrent);

  const location = useLocation()

  const handleCitySwitcher = (event, newCity) => {
    const id = event.key
    const slug = newCity?.slug
    const cities = citiesOfficial.concat(citiesCurrent)
    const city = cities.find(result => result.slug === slug)
    const cityIsSupported = city?.type == 'official' || city?.type == 'early'
    if (city?.location) city.location.fromUser = true

    if (slug) {
      const new_filters = {
        ...filters,
        ...(cityIsSupported && { cities: [slug] })
      }

      setFilters(new_filters)
      setPlacesLoaded(false)

      // Update city and trigger map update
      setCityCurrent(city)
      const location = {
        ...city.location,
        centerpoint: [city.location.longitude, city.location.latitude],
        fromUser: true
      }

      setLocationCurrent(city?.location)
      setMapUpdated(true)
    }

    const path = location?.pathname
    // const path = typeof window !== 'undefined' ? window.location.pathname : '';

    const featuredPath = `/featured`
    const featuresPath = `/features`
    const citiesPath = `/cities`
    const dashboardPath = `/dashboard`
    const mapPath = `/map`
    const eventsPath = `/events`

    // TODO: Also check if there are activites are vibes in the state

    const isHomePage = path == "/"
    const isCityPage = location.pathname.includes(citiesPath)
    const isDashboard = location.pathname.includes(dashboardPath)
    const isMapPage = location.pathname.includes(mapPath)
    const isEvents = location.pathname.includes(eventsPath)
    const isFeaturedPage = location.pathname.includes(featuredPath)
      || location.pathname.includes(featuresPath)
      || isEvents

    const stayOnPage = isMapPage || isFeaturedPage || isDashboard

    // Hanldle different routing cases
    if (isEvents) {
      const targetURL = `/cities/${slug}/events/all`
      navigate(targetURL)
    } else if (!stayOnPage || isHomePage || isCityPage) {
      const targetURL = `/cities/${slug}`
      navigate(targetURL)
    }

  }

  if (isClient != true) return null

  return (
    <div className="main-header-body">
      <div className="container">
        <div className="columns is-mobile is-flex">
          <div className={`column left is-1 is-1-desktop is-1-mobile`}>
            <Logo full={true} />
          </div>
          <div className={`column middle is-flex nav is-3 is-5-desktop is-8-mobile`}>
            {isClient && props.showCity &&
              <>
                {props.citySelected
                  ? <a className='citySelected' href={`/cities/${city}`}>
                      <button className='button button-prev transparent'><ArrowLeft /></button>
                      <span>{selectedCity.title}</span>
                    </a>
                  : <Suspense fallback={null}>
                      <CitySelect
                        city={selectedCity}
                        onChange={handleCitySwitcher}
                        showBorder={false}
                        width={140}
                      />
                    </Suspense>
                }
              </>
            }
            {isClient && props.showSearch &&
              <DisplayField
                label={`Explore vibes…`}
                link={`/search`}
                iconOnly={isPhone ? true : false}
                onChange={handleCitySwitcher} />
            }
          </div>

          <div className={`column right ${isPhone ? 'is-2' : 'is-5'} has-text-right`}>
            <StaticQuery
              query={graphql`
                query MainMenuQuery {
                  allWpMenu(filter: { slug: { eq: "main-navigation" } }) {
                    edges {
                      node {
                        id
                        menuItems {
                          nodes {
                            url
                            label
                            target
                            id
                            cssClasses
                          }
                        }
                        slug
                        name
                      }
                    }
                  }
                }
              `}
              render={data => {
                return (
                  <ul
                    name={data.allWpMenu.edges[0].node.name}
                    className={`menu menu-${data.allWpMenu.edges[0].node.slug}`}
                  >
                    {data.allWpMenu.edges[0].node.menuItems.nodes.map(
                      menuItem => {
                        return (
                          <li
                            key={menuItem.id}
                            className={`post post-id-${menuItem.id
                              } ${menuItem.cssClasses.join(" ")}`}
                          >
                            {renderLink(menuItem)}
                          </li>
                        )
                      }
                    )}
                    {/*
                    TODO: Only show if the user's loggend in and replace with smaller components.
                      <li>
                        <SavedList />
                      </li>
                    */}
                  </ul>
                )
              }}
            />
          </div>
        </div>
      </div>
      {isClient && showBanner &&
        <div id="content">
          <Suspense fallback={null}>
            <SmartBanner
              daysHidden={0}
              daysReminder={0}
              title={'Vibemap ★★★★★'}
              force='ios' />
          </Suspense>
        </div>
      }
    </div>
  )
}

MainMenu.defaultProps = {
  preLoadLinks: false,
  showSearch: true,
  showCity: true,
  showBanner: false
}

export default MainMenu
