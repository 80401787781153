import React from 'react'
import PropTypes from 'prop-types'

import { Link, navigate } from "gatsby"
import { useLocation } from '@reach/router';

import useMobileDetect from 'use-mobile-detect-hook'
const detectMobile = useMobileDetect()

import { MdSearch } from '@react-icons/all-files/md/MdSearch'

import './DisplayField.scss'

function DisplayField({ iconOnly, iconSize, label, link }) {

  const onClick = () => {
    navigate(link)
  }

  // TODO: replace with zustand
  const location = useLocation()
  const isMobile = detectMobile.isMobile()

  const icon = <div className={`searchIcon`}>
    <MdSearch
      className='search-field__icon'
      title='Search'
      size={iconOnly ? iconSize * 1.6 : iconSize} />
  </div>

  return (
    <div className={`displayField ${isMobile ? 'mobile' : 'web'}`}>
      <Link
        className={`search${iconOnly ? ' iconOnly' : ''}`}
        onClick={onClick}
        to={`${link}${location?.search ? location.search : ''}`}
        state={{ fromPath: location.pathname }}
        style={iconOnly
          ? { width: `auto`, border: `none` }
          : {}
        }>
        {iconOnly
          ? icon
          : (
            <>
              {icon}
              <span className='label' style={{
                paddingLeft: `1.2rem`,
                textAlign: 'left',
                top: `.6rem`
              }}>
                {label}
              </span>
            </>
          )
        }
      </Link>
    </div>
  )
}

DisplayField.propTypes = {
  iconSize: PropTypes.number,
  iconOnly: PropTypes.bool,
  label: PropTypes.string,
  link: PropTypes.string,
  showLocation: PropTypes.bool
}

DisplayField.defaultProps = {
  iconSize: 16,
  showLocation: false
}

export default DisplayField